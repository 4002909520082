import { Button, Typography } from '@mui/material';
import { GridColDef, GridRowParams } from '@mui/x-data-grid';
import { IconArrowLeft } from '@tabler/icons';
import { FC } from 'react';
import { usePromiseTracker } from 'react-promise-tracker';
import { useNavigate, useParams } from 'react-router-dom';

import { payoutsAreas } from '../../api/payouts';
import { Breadcrumbs } from '../../components/Breadcrumbs';
import { DownloadButton } from '../../components/DownloadButton';
import { EmptyPayouts } from '../../components/EmptyStates';
import { Table } from '../../components/Table';
import { formatTokens, quantityBeautifierStandard } from '../../helpers/quantityBeautifier'; //eslint-disable-line
import { IPayoutReportDetailProps } from './PayoutReportDetail.d';
import { statusDef } from './statusDefinition';
import { TableHeader } from './TableHeader';

const tableColumns: GridColDef[] = [
  {
    field: 'name',
    headerName: 'Legal Name',
    width: 220,
  },
  {
    field: 'tokens',
    headerAlign: 'right',
    headerName: 'Tokens',
    type: 'number',
    valueFormatter: ({ value }) => value && formatTokens(value),
    width: 130,
  },
  {
    field: 'total',
    headerAlign: 'right',
    headerName: 'Total to distribute',
    type: 'number',
    valueFormatter: ({ value }) => value && quantityBeautifierStandard(value, true),
    width: 130,
  },
  {
    field: 'payment_date',
    headerName: 'Payment date',
    type: 'date',
    width: 130,
  },
  {
    field: 'status',
    headerName: 'Payment status',
    valueFormatter: ({ value }) => value && statusDef[value],
    width: 220,
  },
  {
    field: 'accountType',
    headerName: 'Account Type',
    width: 130,
  },
  {
    field: 'payout_account',
    headerName: 'Payout account',
    width: 220,
  },
];

const PayoutReportDetail: FC<IPayoutReportDetailProps> = ({
  applyFilter,
  investors,
  handleSearch,
  control,
  reportDate,
  clearFilters,
  clearSearch,
  exportReport,
  handleSelection,
  handleSetPayment,
  selection,
  projectName,
}) => {
  const { id } = useParams();
  const nav = useNavigate();
  const goToPayouts = () => nav(`/projects/${id}/payouts`);
  const { promiseInProgress: isLoading } = usePromiseTracker({
    area: payoutsAreas.getPayoutsReportDetail,
  });

  return (
    <>
      <Button
        color="secondary"
        startIcon={<IconArrowLeft />}
        sx={{ fontSize: '12px', mb: 4 }}
        onClick={goToPayouts}
      >
        Back to payouts
      </Button>
      <Breadcrumbs
        current="Create payouts reports"
        linksList={[
          { active: true, label: 'Home', path: '/' },
          { active: true, label: 'Projects', path: '/projects' },
          { active: true, label: 'Project details', path: `/projects/${id}` },
        ]}
      />
      <Typography my={4} variant="h1">
        Payouts details for {projectName} <br /> {reportDate}
      </Typography>
      {!isLoading && (
        <Table
          checkboxSelection
          isRowSelectable={(params: GridRowParams) =>
            params.row.status !== 'paid' && params.row.status !== 'payment_in_process'
          }
          columns={tableColumns}
          rows={investors}
          rowsPerPageOptions={[100]}
          handleSelection={handleSelection}
          components={{ NoRowsOverlay: EmptyPayouts, Toolbar: TableHeader }}
          componentsProps={{
            toolbar: {
              applyFilter,
              clearFilters,
              clearSearch,
              control,
              download: (
                <DownloadButton fileName={`payout_report_${reportDate}`} data={exportReport()} />
              ),
              handleSearch,
              handleSetPayment,
              selection,
            },
          }}
        />
      )}
    </>
  );
};
export { PayoutReportDetail };
